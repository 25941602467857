import React, { useEffect, useRef, useState } from "react";
import {
  ChatContainer,
  Avatar,
  MessageList,
  Message,
  MessageInput,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import send from "../../assets/icons/chat/send.svg";
import { useStoreActions, useStoreState } from "easy-peasy";
import { io } from "socket.io-client";
import Moment from "react-moment";
import parse from "html-react-parser";
import { isOtherDay, isToday } from "../../utils/date-hooks";

import urlParamReplacer from "../../utils/replace-param";
import { useTranslation } from "react-i18next";
import api, { ENDPOINTS } from "../../utils/axios";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./index.scss";

const ChatPage = () => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const chatRef = useRef();
  const messageListRef = useRef();

  const { access_token, userId, profileImage } = useStoreState((store) => store.user);
  const { globalChatId, messages, profanityWords } = useStoreState((store) => store.chat);

  const { chalangeId } = useStoreState((store) => store.game);
  const { getGlobalChatId, getMessages, setMessages, getProfanityWords } = useStoreActions((store) => store.chat);

  const [msgInputValue, setMsgInputValue] = useState("");
  const [allMessages, setAllMessages] = useState([]);

  const [height, setHeight] = useState(200);
  const [offset, setOffset] = useState(0);
  const [newRequestCanBeDone, setNeRequestCanBeDone] = useState(true);
  const limit = 20;

  useEffect(() => {
    getProfanityWords();
  }, [getProfanityWords]);

  console.log()

  useEffect(() => {
    setAllMessages(messages);
  }, [messages]);

  useEffect(() => {
    if (chalangeId) {
      getGlobalChatId({ challengeId: chalangeId });
    }
    if (globalChatId) {
      getMessages({ conversationId: globalChatId, userId, offset: 0, limit });
      setOffset((prev) => prev + limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chalangeId, globalChatId, userId]);

  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const socketInstance = io(process.env.REACT_APP_SOCKET_PATH, {
      transports: ["websocket"],
      reconnectionDelayMax: 10000,
      pingTimeout: 60000,
      query: {
        authorization: access_token,
      },
      auth: {
        token: access_token,
      },
    });
    function onConnect() {
      console.log("CONNECTED");
      setIsConnected(true);
      console.log(socketInstance);
    }

    function onException() {
      console.log("onException");
      setIsConnected(false);
    }

    function onDisconnect() {
      console.log("DISCONNECTED");
      setIsConnected(false);
      setTimeout(() => {
        socketInstance.connect();
      }, 2000);
    }

    function onReceiveMessage(value) {
      if (value?.lastMessage.conversationId === globalChatId) {
        const newMessage = {
          text: value?.lastMessage.text,
          image: value?.lastMessage?.mediaURL,
          direction: value?.lastMessage.sender._id === userId ? "outgoing" : "incoming",
          sender: value?.lastMessage.sender.nickname,
          sentTime: value?.lastMessage.created,
          profilePicture: value?.lastMessage.sender.profilePicture,
        };
        setAllMessages((prev) => [...prev, newMessage]);
      }
    }

    socketInstance.on("connect", onConnect);
    socketInstance.on("disconnect", onDisconnect);
    socketInstance.on("receive_message", onReceiveMessage);
    socketInstance.on("exception", onException);
    setSocket(socketInstance);

    return () => {
      console.log("return");
      socketInstance.off("connect", onConnect);
      socketInstance.off("disconnect", onDisconnect);
      socketInstance.off("receive_message", onReceiveMessage);
      setAllMessages([]);
      setMessages({ messages: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setHeight(chatRef?.current?.offsetTop);
  }, [chatRef]);

  const handleSend = async () => {
    if (msgInputValue && msgInputValue.trim() !== "") {
      let isProfane = false;
      
      const rawInput = msgInputValue;
      
      const cleanMsg = msgInputValue
          .toLowerCase()
          .replace(/[^a-zA-Z0-9\s]/g, "")
          .trim();

      const collapsedMsg = cleanMsg.replace(/\s+/g, "");

      const words = cleanMsg.split(/\s+/);
      
      profanityWords.forEach((profanityWord) => {
        if (rawInput.toLowerCase().includes(profanityWord) || words.includes(profanityWord) || collapsedMsg === profanityWord) {
          isProfane = true;
        }
      });
      
      if (!isProfane && isConnected) {
        const newMessage = {
          text: msgInputValue,
          direction: "outgoing",
          sender: "You",
          sentTime: new Date(),
        };

        setAllMessages((prev) => [...prev, newMessage]);
        
        socket.emit("send_message", {
          conversationId: globalChatId,
          message: {
            text: msgInputValue,
            mediaURL: null,
          },
        });
      } else {
        console.log("Profanity detected: message not sent.");
      }
      
      setMsgInputValue("");
      inputRef.current.focus();
    }
  };



  const onYReachStart = async () => {
    if (newRequestCanBeDone) {
      const conversationId = globalChatId;
      setNeRequestCanBeDone(false);
      try {
        const { data } = await api.get(
          urlParamReplacer(ENDPOINTS.CHAT.MESSAGES, conversationId) + `?offset=${offset}&limit=${limit}`
        );

        const messages = data?.messages.map((item) => {
          return {
            text: item.text,
            image: item.mediaURL,
            direction: item.sender._id === userId ? "outgoing" : "incoming",
            sender: item.sender.nickname,
            sentTime: item.created,
            profilePicture: item.sender.profilePicture,
          };
        });

        setAllMessages((prev) => [...messages.reverse(), ...prev]);
        if (messages?.length < limit) {
          setNeRequestCanBeDone(false);
        } else {
          setTimeout(() => {
            setNeRequestCanBeDone(true);
            setOffset((prev) => prev + limit);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setNeRequestCanBeDone(true);
      }
    }
  };


  return (
    <div
      className="chat container"
      ref={chatRef}
      style={{ height: `calc(100dvh - ${height}px - 20px)` }}
    >
      <ChatContainer className="chat_container">
        <MessageList
          scrollBehavior="auto"
          className="message_list"
          onYReachStart={onYReachStart}
          loadingMorePosition="top"
          autoScrollToBottom={true}
          autoScrollToBottomOnMount={true}
          ref={messageListRef}
          disableOnYReachWhenNoScroll={true}
        >
          {allMessages?.length > 0 &&
            allMessages?.map((message, i) => (
              <React.Fragment key={i}>
                {i > 0 && isOtherDay(message?.sentTime, allMessages?.[i - 1]?.sentTime) && (
                  <MessageSeparator className="separator">
                    {isToday(new Date(message?.sentTime)) ? (
                      <span className="separator-content">{t("chat.today")}</span>
                    ) : (
                      <Moment format="DD MMM YYYY" className="separator-content">
                        {message.sentTime}
                      </Moment>
                    )}
                  </MessageSeparator>
                )}
                {message.direction === "incoming" && (
                  <Message
                    model={message}
                    className={`message ${
                      i > 0 && allMessages[+i - 1]?.sender === message.sender && message?.profilePicture ? "no-avatar" : ""
                    } ${message?.image ? "with-image" : "text"}`}
                    avatarPosition="tl"
                  >
                    {((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && message?.profilePicture && (
                      <Avatar
                        src={
                          message.profilePicture?.includes("http")
                            ? message.profilePicture
                            : message.profilePicture
                        }
                        name={message.sender}
                        style={{ width: "30px" }}
                      />
                    )}
                    <Message.CustomContent>
                      {((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && (
                        <>
                          <span className="user_name">{message.sender}</span> <br />
                        </>
                      )}
                      {parse(`${message?.text}`)}
                      <span className="sent_time">
                        <Moment format="HH:mm">{message.sentTime}</Moment>
                      </span>
                    </Message.CustomContent>
                    {message.image && (
                      <Message.ImageContent
                        src={message.image}
                        alt="Akane avatar"
                        className="message-with-image"
                      />
                    )}
                  </Message>
                )}
                {message.direction === "outgoing" && (
                  <Message
                    model={message}
                    className={`message ${
                      i > 0 && allMessages[+i - 1]?.sender === message.sender && profileImage ? "no-avatar" : ""
                    } ${message?.image ? "with-image" : "text"}`}
                    avatarPosition="tr"
                  >
                    {((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && profileImage && (
                      <Avatar src={profileImage} name={message.sender} style={{ width: "30px" }} />
                    )}
                    <Message.CustomContent>
                      {((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && (
                        <>
                          <span className="user_name">{message.sender}</span> <br />
                        </>
                      )}
                      {parse(`${message?.text}`)}
                      <span className="sent_time">
                        <Moment format="HH:mm">{message.sentTime}</Moment>
                      </span>
                    </Message.CustomContent>
                    {message.image && (
                      <Message.ImageContent
                        src={message.image}
                        alt="Akane avatar"
                        className="message-with-image"
                      />
                    )}
                  </Message>
                )}
              </React.Fragment>
            ))}
        </MessageList>

        <div as={MessageInput} className="input_container">
          <MessageInput
            ref={inputRef}
            onChange={(msg) => setMsgInputValue(msg)}
            // onClick={() => scrollToBottom()}
            value={msgInputValue}
            sendButton={false}
            attachButton={false}
            onSend={handleSend}
            autoCorrect={false}
            spellCheck={false}
            autoComplete="off"
          />
          <div className="send_button" onClick={handleSend}>
            <img src={send} alt="send" />
          </div>
        </div>
      </ChatContainer>
    </div>
  );
};

export default ChatPage;

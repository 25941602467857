import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import api from "./utils/axios";
import getParamValue from "./utils/get-param";
import i18next from "i18next";
import { ChatPage, ErrorPage, GamesPage, LeaderboardsPage, PrizesPage } from "./pages";
import { Layout } from "./components";

function App() {
  const { access_token, email, firstName, userId } = useStoreState((state) => state.user);
  const { login, getRefreshedToken, logout, setIsLoading, getLang } = useStoreActions(
      (actions) => actions.user
  );

  const location = useLocation();

  useEffect(() => {
    api.setupApiInterceptors(getRefreshedToken, logout);
    setIsLoading(false);

    return () => {
      api.clearInterceptors();
    };
  }, [getRefreshedToken, logout, setIsLoading]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = getParamValue(params, "token");
    const lang = getParamValue(params, "lang") || "en";

    const fetchData = async () => {
      if (token) {
        await login({ token, lang });
      } else {
        getLang(lang);
      }
      i18next.changeLanguage(lang);
    };

    fetchData().catch(console.error);
  }, [location.search, login, getLang]);

  useEffect(() => {
    let script;

    const loadFreshchatWidget = () => {
      script = document.createElement("script");
      script.src = "//eu.fw-cdn.com/10425374/297053.js";
      script.setAttribute("chat", "true");
      script.setAttribute("widgetId", "085b5958-f4ac-4985-ba6a-b7e9c6077209")
      document.body.appendChild(script);

      script.onload = () => {
        const checkFreshchatLoaded = () => {
          if (typeof window.fcWidget !== "undefined") {
            window.fcWidget.on("widget:loaded", function () {
              if (userId) window.fcWidget.setExternalId(userId);
              if (firstName) window.fcWidget.user.setFirstName(firstName);
              if (email) window.fcWidget.user.setEmail(email);
            });
          } else {
            setTimeout(checkFreshchatLoaded, 500);
          }
        };
        checkFreshchatLoaded();
      };
    };

    if (access_token) {
      if (typeof window.fcWidget === "undefined") {
        loadFreshchatWidget();
      } else {
        if (userId) window.fcWidget.setExternalId(userId);
        if (firstName) window.fcWidget.user.setFirstName(firstName);
        if (email) window.fcWidget.user.setEmail(email);
      }

      if (location.pathname.startsWith("/chat")) {
        if (typeof window.fcWidget !== "undefined") {
          window.fcWidget.hide();
        }
      } else {
        if (typeof window.fcWidget !== "undefined") {
          window.fcWidget.show();
        }
      }
    }

    return () => {
      if (typeof window.fcWidget !== "undefined") {
        window.fcWidget.hide();
      }
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [access_token, location.pathname, email, firstName, userId]);

  return (
      <div className="App">
        {access_token ? getMainRoutes() : getErrorRoutes()}
      </div>
  );
}

const getMainRoutes = () => (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<GamesPage />} />
        <Route path="leaderboards" element={<LeaderboardsPage />} />
        <Route path="prizes" element={<PrizesPage />} />
        <Route path="chat" element={<ChatPage />} />
      </Route>
    </Routes>
);

const getErrorRoutes = () => (
    <Routes>
      <Route path="/" element={<ErrorPage />} />
    </Routes>
);

export default App;

// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_EN from "../assets/locales/en/translation.json";
import translation_HU from "../assets/locales/hu/translation.json";
import translation_SR from "../assets/locales/sr/translation.json";

const resources = {
  en: {
    translation: translation_EN,
  },
  hu: {
    translation: translation_HU,
  },
  sr: {
    translation: translation_SR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  }
});

export default i18n;

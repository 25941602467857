import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api, { ENDPOINTS } from "../../utils/axios";
import moment from "moment";
import "moment/locale/hu";
import "moment/locale/sr";

const leaderboardsModel = {
  // DATA
  leaderboardsComplete: false,
  leaderboards: [],
  selectDayOptions: [],
  selectStageOptions: [],
  intervalsMonths: [],
  intervalsWeeks: [],
  isLoading: false,
  offset: 0,

  // ACTIONS
  setLeaderboard: action((state, payload) => {
    const newData = payload.data.map((item, index) => {
      return {
        id: item._id,
        name: item.user?.nickname || item?.teamDetails?.name || "",
        image: item.user?.profilePicture || item?.teamDetails?.emblemUrl,
        points: item?.points || item?.earnedPoints || 0,
        position: item?.position || index + 1,
      };
    });
    if (payload.offset === 0) {
      state.leaderboards = newData;
    } else {
      state.leaderboards = [...state.leaderboards, ...newData];
    }
  }),
  setLeaderboardComplete: action((state, payload) => {
    state.leaderboardsComplete = payload;
  }),
  resetLeaderboard: action((state) => {
    state.offset = 0;
    state.leaderboardsComplete = false;
    state.leaderboards = [];
  }),
  setIntervalsWeeks: action((state, payload) => {
    state.intervalsWeeks = payload;
  }),
  setIntervalsMonths: action((state, payload) => {
    state.intervalsMonths = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  increaseOffset: action((state, payload) => {
    state.offset += payload;
  }),

  // THUNKS
  getSelectData: thunk(async (actions, payload) => {
    const { data } = await api.get(ENDPOINTS.LEADERBOARDS.SELECT_OPTIONS);
    moment.locale(payload);
    const weeks = data?.intervalsWeeks?.map((week, index) => {
      return {
        ...week,
        label: `${moment(week.startDate)
          .utc()
          .format("DD MMM")} - ${moment(week.endDate).utc().format("DD MMM")}`,
        value: { ...week },
      };
    })
    ?.reverse();
      
    const months = data?.intervalsMonths?.map((month) => {
      return {
        ...month,
        label: `${moment(month.startDate).utc().format("MMMM YYYY")}`,
        value: { ...month },
      };
    });
    actions.setIntervalsWeeks(weeks);
    actions.setIntervalsMonths(months);
  }),
  getLeaderboardsUsers: thunk(async (actions, payload, { getState }) => {
    actions.setIsLoading(true);
    const isComplete = getState().leaderboardsComplete;
    if (!isComplete) {
      let url = "";
      url += ENDPOINTS.LEADERBOARDS.LEADERBOARDS_USERS + "?challengeId=" + payload.challengeId ;

      if (payload.type !== undefined) {
        url += "&type=" + payload.type;
      }
      if (payload.startDate !== undefined && payload.endDate !== undefined) {
        const utcStartDate = new Date(payload.startDate);
        const utcStartDateMinus2Hours = new Date(utcStartDate);
        utcStartDateMinus2Hours.setUTCHours(utcStartDateMinus2Hours.getUTCHours() - 2);

        const utcEndDate = new Date(payload.endDate);
        const utcEndDateMinus2Hours = new Date(utcEndDate);
        utcEndDateMinus2Hours.setUTCHours(utcEndDateMinus2Hours.getUTCHours() - 2);

        url +=
          "&startDate=" +
          utcStartDateMinus2Hours.toISOString() +
          "&endDate=" +
          utcEndDateMinus2Hours.toISOString();
      }
      if (payload.offset !== undefined && payload.limit !== undefined) {
        url += "&offset=" + payload.offset + "&limit=" + payload.limit;
      }
      const { data } = await api.get(url);
      if (data?.length === 0 || data?.length < 20) actions.setLeaderboardComplete(true);
      else actions.setLeaderboardComplete(false);
      actions.setLeaderboard({ data, offset: payload.offset });
      actions.setIsLoading(false);
    }
  }),
  resetLeaderboards: thunk(async (actions) => {
    actions.resetLeaderboard();
  }),
};
export default leaderboardsModel;
